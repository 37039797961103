import {
	type Dispatch,
	type PropsWithChildren,
	type ReactNode,
	type SetStateAction,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';

import { Sheet, SheetContent } from '@adframe/ui/components/sheet';

export const SheetContext = createContext<{
	content: ReactNode | undefined;
	setContent: Dispatch<SetStateAction<ReactNode>>;
	close: () => void;
	onClose: (cb: () => void) => void;
}>({
	content: undefined,
	setContent: () => {},
	close: () => {},
	onClose: () => {},
});

export function SheetProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const [content, setContent] = useState<ReactNode | undefined>();
	const onCloseRef = useRef(() => {});

	const isOpen = useMemo(() => !!content, [content]);

	const close = useCallback(() => {
		setContent(undefined);
		onCloseRef.current();

		setTimeout(() => {
			window.BrevoConversations('show');
		}, 300);
	}, []);

	const onOpenChange = useCallback((open: boolean) => {
		if (!open) {
			close();
		}
	}, []);

	// to improve
	if (isOpen) {
		window.BrevoConversations('hide');
	}

	const onClose = useCallback((cb: () => void) => {
		onCloseRef.current = cb;
	}, []);

	return (
		<SheetContext.Provider
			value={{
				content,
				setContent,
				close,
				onClose,
			}}
		>
			<Sheet open={isOpen} onOpenChange={onOpenChange}>
				<SheetContent
					side='right'
					overlay={true}
					onInteractOutside={(e) => e.preventDefault()}
					mode={'light'}
					width={'large'}
				>
					{content}
				</SheetContent>
			</Sheet>
			{children}
		</SheetContext.Provider>
	);
}

export const useSheetContext = () => {
	const context = useContext(SheetContext);

	if (!context) {
		throw new Error('useSheetContext must be used within an SheetProvider');
	}
	return context;
};
