import { queryOptions } from '@tanstack/react-query';
import getApiClient from '../services/internal-api/get-api-client';

export const listAccessibleCampaignsQueryOptions = () =>
	queryOptions({
		queryKey: ['accessible-campaigns'],
		staleTime: 10_000,
		queryFn: async () => {
			const client = getApiClient();

			return client.campaigns.accessible.$get().then((result) => result.json());
		},
	});

export const getCampaignQueryOptions = (campaignId: string) =>
	queryOptions({
		queryKey: ['campaigns', campaignId],
		staleTime: 10_000,
		queryFn: () =>
			getApiClient()
				.campaigns[':campaignId'].$get({
					param: {
						campaignId,
					},
				})
				.then((result) => result.json()),
	});
