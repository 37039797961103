import { QueryClient } from '@tanstack/react-query';
import { HTTPException } from 'hono/http-exception';

export * from './campaign';
export * from './member';
export * from './creative';
export * from './client';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error: unknown) => {
				if (error instanceof HTTPException && error.status === 404) {
					return false;
				}

				return failureCount < 3;
			},
		},
	},
});
