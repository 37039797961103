import { TooltipProvider } from '@adframe/ui/components/tooltip';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Suspense } from 'react';
import { Toaster } from 'sonner';
import type useAuth from '#hooks/use-auth.js';
import { AlertProvider } from '#providers/alert.js';
import { ModalProvider } from '#providers/modal.js';
import { SheetProvider } from '#providers/sheet.js';
import type { queryClient } from '#queries/index.js';

export interface RouterContext {
	auth: ReturnType<typeof useAuth>;
	queryClient: typeof queryClient;
}

export const Route = createRootRouteWithContext<RouterContext>()({
	component: RootComponent,
});

function RootComponent() {
	return (
		<>
			<TooltipProvider delayDuration={300}>
				<AlertProvider>
					<ModalProvider>
						<SheetProvider>
							<Outlet />
						</SheetProvider>
					</ModalProvider>
				</AlertProvider>
			</TooltipProvider>
			<Toaster />
			<Suspense>
				<TanStackRouterDevtools initialIsOpen={false} position='bottom-right' />
			</Suspense>
		</>
	);
}
