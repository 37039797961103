import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { StrictMode, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';

import '@adframe/ui/globals.css';

import './i18n';
import { createRouter } from '@tanstack/react-router';
import NotFound from '#components/NotFound.js';
import { AuthProvider, useAuthContext } from './providers/auth';
import { queryClient } from './queries';
import { routeTree } from './routeTree.gen';

const router = createRouter({
	routeTree,
	context: { auth: undefined!, queryClient },
	defaultPreload: 'intent',
	defaultPreloadStaleTime: 0,
	notFoundMode: 'fuzzy',
	trailingSlash: 'never',
	defaultNotFoundComponent: () => <NotFound />,
});

const InnerApp = () => {
	const authContext = useAuthContext();
	return <RouterProvider router={router} context={{ auth: authContext }} />;
};

const ReactQueryDevtools =
	process.env.NODE_ENV === 'production'
		? () => null // Render nothing in production
		: lazy(() =>
				// Lazy load in development
				import('@tanstack/react-query-devtools').then((res) => ({
					default: res.ReactQueryDevtools,
				})),
			);

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<InnerApp />
				<Suspense>
					<ReactQueryDevtools buttonPosition='top-right' client={queryClient} />
				</Suspense>
			</AuthProvider>
		</QueryClientProvider>
	);
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
	interface RouterContext {}

	interface StaticDataRouteOption {
		guest?: boolean;
	}
}

const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);

	root.render(
		<StrictMode>
			<App />
		</StrictMode>,
	);
}
