import { create } from 'zustand';
import { combine } from 'zustand/middleware';

import type { Role } from '@adframe/access';

export default create(
	combine(
		{
			role: undefined as Role | undefined | null,
		},
		(set) => ({
			setRole: (role: Role | undefined | null) => set({ role }),
		}),
	),
);
