import { type PropsWithChildren, Suspense, createContext, useContext, useEffect } from 'react';

import useAuth from '../hooks/use-auth';

export const AuthContext = createContext<ReturnType<typeof useAuth> | null>(null);

export function AuthProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const auth = useAuth();

	useEffect(() => {
		auth.authenticate();
	}, []);

	if (auth.status === 'unknown') {
		return;
	}

	return (
		<Suspense>
			<AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
		</Suspense>
	);
}

export const useAuthContext = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuthContext must be used within an AuthProvider');
	}
	return context;
};
