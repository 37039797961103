import type React from 'react';
import {
	type Dispatch,
	type PropsWithChildren,
	type ReactNode,
	type SetStateAction,
	createContext,
	useContext,
	useState,
} from 'react';

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@adframe/ui/components/alert-dialog';
import type { Button } from '@adframe/ui/components/button';

export const AlertDialogDefault = ({
	title,
	description,
	onConfirm,
	variant,
}: {
	title: string;
	description?: string;
	variant?: React.ComponentPropsWithoutRef<typeof Button>['variant'];
	onConfirm: () => void;
}) => {
	return (
		<AlertDialogContent>
			<AlertDialogHeader>
				<AlertDialogTitle>{title}</AlertDialogTitle>
				{description && <AlertDialogDescription>{description}</AlertDialogDescription>}
			</AlertDialogHeader>
			<AlertDialogFooter>
				<AlertDialogCancel>Cancel</AlertDialogCancel>
				<AlertDialogAction onClick={onConfirm} variant={variant}>
					Continue
				</AlertDialogAction>
			</AlertDialogFooter>
		</AlertDialogContent>
	);
};

export const AlertContext = createContext<{
	content: ReactNode | undefined | { title: string; description: string; onConfirm: () => void };
	close: () => void;
	setContent: Dispatch<SetStateAction<ReactNode>>;
}>({
	content: undefined,
	setContent: () => {},
	close: () => {},
});

export function AlertProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const [content, setContent] = useState<ReactNode | undefined>();

	const onOpenChange = (open: boolean) => {
		if (!open) {
			setContent(undefined);
		}
	};

	const close = () => {
		setContent(undefined);
	};

	return (
		<AlertContext.Provider
			value={{
				content,
				setContent,
				close,
			}}
		>
			<AlertDialog open={!!content} onOpenChange={onOpenChange}>
				{content}
			</AlertDialog>
			{children}
		</AlertContext.Provider>
	);
}

export const useAlertContext = () => {
	const context = useContext(AlertContext);
	if (!context) {
		throw new Error('useAlertContext must be used within an AlertProvider');
	}
	return context;
};
