import { create } from 'zustand';
import { combine } from 'zustand/middleware';

import type { Account } from '@adframe/database-account/types';

import { useAccessStore } from '.';

export default create(
	combine(
		{
			account: undefined as Account | undefined | null,
		},
		(set) => ({
			setAccount: (account: Account | undefined | null) => {
				set({ account });
				if (account) {
					window.BrevoConversations('updateIntegrationData', {
						email: account.email,
						firstName: account.firstName,
						lastName: account.lastName,
						role: account.role,
						organizationId: account.organizationId,
						organizationName: account.organization.name,
						browserLanguage: navigator.language,
					});
				}

				useAccessStore.getState().setRole(account?.role);
			},
		}),
	),
);
