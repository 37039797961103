import { create } from 'zustand';
import { combine, persist } from 'zustand/middleware';

interface CollapseState {
	campaignGroupOpened: `cgp_${string}` | undefined;
}

export default create(
	persist(
		combine({ campaignGroupOpened: undefined } as CollapseState, (set) => ({
			setOpen: (id: CollapseState['campaignGroupOpened']) =>
				set(() => ({ campaignGroupOpened: id })),
			setCollapse: (id: `cgp_${string}`, value: boolean) =>
				set(() => ({
					campaignGroupOpened: value ? id : undefined,
				})),
		})),
		{
			name: 'campaignGroupCollapse',
		},
	),
);
