import { useCallback, useEffect, useState } from 'react';

export default function useOpenWindow<
	TPayload extends Record<string, unknown>,
	P extends Record<string, unknown> | undefined = undefined,
>(type: string, eventHandler?: (data: TPayload, param: P) => void) {
	const [param, setParams] = useState<P>();

	const sendToParent = useCallback(
		(payload: TPayload) => {
			const { opener } = window;
			if (!opener) throw new Error('Parent window has been closed');
			window.opener.postMessage({
				type,
				payload,
			});
		},
		[type],
	);

	const open = useCallback((url: string | URL, p?: P) => {
		setParams(p);
		window.open(url, type);
	}, []);

	const handler = useCallback(
		(message: MessageEvent<{ type?: string; payload?: TPayload }>) => {
			if (message.data.type === type && message.data.payload) {
				console.info('message', message);
				if (typeof eventHandler === 'function') {
					eventHandler(message.data.payload, param as P);
				}
			}
		},
		[type, eventHandler],
	);

	useEffect(() => {
		window.addEventListener('message', handler);
		return () => {
			window.removeEventListener('message', handler);
		};
	}, [type, handler]);

	return {
		open,
		sendToParent,
	};
}
