import { type PropsWithChildren, type ReactNode, createContext, useContext, useState } from 'react';

import { Dialog, DialogContent } from '@adframe/ui/components/dialog';

export const ModalContext = createContext<{
	content: ReactNode | undefined;
	close: () => void;
	setContent: (content: ReactNode | undefined) => void;
}>({
	content: undefined,
	setContent: () => {},
	close: () => {},
});

export function ModalProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const [content, setContent] = useState<ReactNode | undefined>();
	const [open, setOpen] = useState<boolean>(false);

	const onOpenChange = (open: boolean) => {
		setOpen(open);
		if (!open) {
			setTimeout(() => setContent(undefined), 300);
		}
	};

	const onOpen = (content: ReactNode | undefined) => {
		setContent(content);
		setOpen(true);
	};

	const close = () => {
		setOpen(false);
		setTimeout(() => setContent(undefined), 300);
	};

	return (
		<ModalContext.Provider
			value={{
				content,
				setContent: onOpen,
				close,
			}}
		>
			<Dialog open={!!open} onOpenChange={onOpenChange}>
				<DialogContent className='max-h-[calc(100vh-5rem)] max-w-[calc(100vw-50rem)]'>
					{content}
				</DialogContent>
			</Dialog>
			{children}
		</ModalContext.Provider>
	);
}

export const useModalContext = () => {
	const context = useContext(ModalContext);
	if (!context) {
		throw new Error('useModalContext must be used within an ModalProvider');
	}
	return context;
};
